import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import TadContent from './TadContent';
import { Container } from 'react-bootstrap';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Open Sans', sans-serif;
  color: var(--tad-green);
  margin-top: 2rem;
  @media (max-width: 768px) {
    position: relative;
    height: auto;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 1rem 0;
  text-align: left;
  font-weight: bold;
`;

const Text = styled.p`
  margin: 1.325rem 0;
  text-align: left;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 2rem 1rem 2rem;
  img {
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    padding: 1rem;
    img {
      width: 100%;
    }
  }
`;

const TadContentBrands = () => {
  return (
    <TadContent selectedMenu={'Brands'}>
      <Container>
        <Wrapper>
          <Row>
            <Col md={6} className="animate__animated animate__fadeIn">
              <TextContent>
                <Title>Discover the Elite: Top Brands at TAD Duty Free</Title>
                <Text>
                  Step into the luxurious realm of TAD Duty Free, where a meticulously curated world of top-tier brands
                  beckons. Here, your passions and preferences take center stage, with an unparalleled selection of
                  premium spirits, enchanting perfumes, and much more, all gathered with your ultimate satisfaction in
                  mind. Our commitment to excellence ensures that every item on our shelves represents the pinnacle of
                  quality and craftsmanship, promising you nothing but the finest indulgences for your journey.
                </Text>
              </TextContent>
              <TextContent>
                <StaticImage src="../../images/tad/tad_page_2.jpeg" alt="Brands" placeholder="blurred" />
              </TextContent>
            </Col>
            <Col md={6} className="animate__animated animate__fadeIn">
              <TextContent>
                <Title>Unveiling New Special Offers: Elevate Your Shopping Experience</Title>
                <Text>
                  Prepare for a thrilling adventure every time you visit TAD Duty Free, where excitement and exclusivity
                  soar to new heights. Our ever-changing landscape of unbeatable deals and exclusive promotions is
                  designed to make each shopping experience uniquely rewarding. With new special offers unveiled during
                  each visit, the opportunity to discover exceptional value and rare finds is endless. Whether you're
                  seeking the perfect gift or a luxurious treat for yourself, our special offers provide an
                  extraordinary chance to acquire premium products at irresistible prices.
                </Text>
              </TextContent>
              <TextContent>
                <Text>
                  Embark on a journey of discovery and delight with TAD Duty Free, where the world's most coveted brands
                  meet exceptional deals, crafting a shopping experience that's truly unparalleled. Explore our stores
                  and let the allure of new special offers captivate and enhance your pre-flight adventure, making every
                  visit memorable.
                </Text>
              </TextContent>
              <TextContent>
                <Row className="d-flex justify-content-between flex-row w-100">
                  <Col md={6} className="mb-2">
                    <StaticImage src="../../images/tad/tad_main_1.jpeg" alt="Brands" placeholder="blurred" />
                  </Col>
                  <Col md={6} className="mb-2">
                    <StaticImage src="../../images/tad/tad_main_2 copy.jpeg" alt="Brands" placeholder="blurred" />
                  </Col>
                </Row>
              </TextContent>
              {/* <StaticImage src="../../images/tad/tad_page_2.jpeg" alt="Brands" placeholder="blurred" /> */}
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </TadContent>
  );
};

export default TadContentBrands;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
